.modal {
    width: 20em;
    background: lightgray;
    border-radius: 1em;
    padding: 1em;
    position: absolute;
    flex-direction: column;
    align-items: center;
    left: calc(50vw - 10em);
    top: calc(50vh - 10em);
  }